import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isString } from 'lodash'
dayjs.extend(utc)

export const convertToUTCKeepingTime = (localTime: string | Date | dayjs.Dayjs) => {
  if (!isString(localTime)) {
    return dayjs(localTime).utcOffset(0, true)
  }

  const strippedTime = (localTime as string).endsWith('Z') ? (localTime as string).slice(0, -1) : localTime

  return dayjs(strippedTime).utcOffset(0, true)
}

export const toServerTime = (date: Date | dayjs.Dayjs | string) => {
  const utcTime = convertToUTCKeepingTime(date)

  return utcTime
}

export const toClientTime = (date: string) => {
  const removeTz = (date || '').endsWith('Z') ? date.slice(0, -1) : date
  return dayjs(removeTz).toDate()
}

export default dayjs
